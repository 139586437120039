import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoginForm } from "../components/LoginForm";
import {
  authenticateUser,
  validateToken,
} from "../redux/actions/LoginActions.js";
import { ROLE } from "../constants";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // console.warn("show me some console");
    const bodyParameters = {
      user_name: this.state.username,
      password: this.state.password,
    };
    this.props.authenticateUser(bodyParameters);
  };

  componentWillMount() {
    let sessionValue = JSON.parse(localStorage.getItem("data"));
    let params = {
      token: sessionValue?.loginData?.user?.token,
    };

    if (sessionValue) {
      const bodyParameters = {
        user_name: sessionValue.loginData.user_name,
        password: sessionValue.loginData.password,
      };

      this.props
        .authenticateUser(bodyParameters)
        .then(() => {
          // Authentication successful, now validate the token
          return this.props.validateToken(params);
        })
        .then((res) => {
          const allowedRoles = [
            ROLE.Admin,
            ROLE.Purchase,
            ROLE.Sales,
            ROLE.Orders,
            ROLE.SuperAdmin,
            ROLE.Store,
            ROLE.StorePurchase,
          ];

          if (
            sessionValue &&
            sessionValue.loginData &&
            sessionValue.loginData.user &&
            this.props.loginData.tokenValid &&
            allowedRoles.includes(
              sessionValue.loginData.user.role.toLowerCase()
            )
          ) {
            // Route to /admin if the user's role matches any of the allowed roles
            this.props.history.push("/admin");
          } else {
            // Default route for no valid role
            this.props.history.push("/");
          }
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    } else {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(props, nextProps) {
    const loginData = props.loginData;
    console.log("show login data", loginData);
    if (loginData.isLogin) {
      this.setState({ loginData }, () => {
        localStorage.setItem("data", JSON.stringify(loginData));

        const role = loginData?.loginData?.user?.role.toLowerCase();
        const roleRoutes = {
          [ROLE.Admin]: "/admin",
          [ROLE.Orders]: "/admin",
          [ROLE.Sales]: "/admin",
          [ROLE.Purchase]: "/admin",
          [ROLE.SuperAdmin]: "/admin",
          [ROLE.Store]: "/admin",
          [ROLE.StorePurchase]: "/admin",
        };

        const route = roleRoutes[role];
        if (route) {
          props.history.push(route);
        }
      });
    } else {
      this.setState({ message: loginData.message_error });
    }
  }

  render() {
    return (
      <div className="vh-100">
        <LoginForm
          content={this.state}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (params) => dispatch(authenticateUser(params)),
    validateToken: (params) => dispatch(validateToken(params)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
