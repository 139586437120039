import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import Logo from "../../assets/images/Logo.png";
import Min_Logo from "../../assets/images/Logo_min.png";
import { SettingOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const CommonSidebar = ({ role, sidebar, user_roles }) => {
  const [collapsed, setCollapsed] = useState(sidebar.sidebarClosed);
  const [current, setCurrent] = useState();
  const [openKeys, setOpenKeys] = useState([""]);

  useEffect(() => {
    setCollapsed(sidebar.sidebarClosed);
  }, [sidebar.sidebarClosed]);

  // console.log("user roles:", user_roles);

  const getItem = (label, key, icon, children) => ({
    key,
    icon,
    children,
    label,
  });

  const roleMenuItems = {
    admin: [
      getItem(
        "Sales",
        "sub1",
        <i style={{ fontSize: "0.8rem" }} className="fa fa-users"></i>,
        [
          getItem("Clients Details", "sub1_1", null, [
            getItem(<Link to="/sales/clients">View Clients</Link>, "1"),
            getItem(<Link to="/sales/client/new">Add New Client</Link>, "2"),
          ]),
          getItem("Lead Details", "sub1_2", null, [
            getItem(<Link to="/sales/leads">View Leads</Link>, "3"),
            getItem(<Link to="/sales/leads/new">New Lead</Link>, "4"),
          ]),
          getItem("Quotation", "sub1_3", null, [
            getItem(<Link to="/sales/invoicelist">View Quotations</Link>, "5"),
            getItem(
              <Link to="/sales/create-quotation">New Quotation</Link>,
              "6"
            ),
          ]),
        ]
      ),
      getItem(
        "Purchase",
        "sub2",
        <i style={{ fontSize: "0.9rem" }} className="fa fa-shopping-cart"></i>,
        [
          getItem("Vendor Details", "sub2_1", null, [
            getItem(<Link to="/purchase/vendors">View Vendors</Link>, "7"),
            getItem(
              <Link to="/purchase/vendors/new">Add New Vendor</Link>,
              "8"
            ),
          ]),
          getItem("Purchase Order", "sub2_2", null, [
            getItem(<Link to="/purchase/list">View PO's</Link>, "9"),
            getItem(<Link to="/purchase/new">New PO</Link>, "10"),
          ]),
        ]
      ),
      getItem(
        "Production",
        "sub3",
        <i style={{ fontSize: "0.7rem" }} className="fa fa-industry"></i>,
        [
          getItem(<Link to="/orders/list">View Job Orders</Link>, "11"),
          getItem(<Link to="/orders/create-job">New Job Order</Link>, "12"),
        ]
      ),
      getItem(
        "Store",
        "sub4",
        <i style={{ fontSize: "0.9rem" }} className="fa fa-home"></i>,
        [
          getItem("MRN", "sub4_1", null, [
            getItem(<Link to="/mrn/list">View MRN</Link>, "13"),
            getItem(<Link to="/mrn/create-mrn">New MRN</Link>, "14"),
          ]),
          getItem("Inventory", "sub4_2", null, [
            getItem(<Link to="/store/create-item">Add Item</Link>, "15"),
            getItem(<Link to="/store/add-stock">Add Stock</Link>, "16"),
            getItem(<Link to="/store/assign-stock">Assign Stock</Link>, "17"),
            getItem(<Link to="/store/item-stock">View Stock</Link>, "18"),
            // getItem(<Link to="/store/item-ledger">View Ledger Item</Link>, "28"),
          ]),
        ]
      ),
      getItem(
        "Exports",
        "sub5",
        <i style={{ fontSize: "0.7rem" }} className="fa fa-ship"></i>,
        [
          getItem(<Link to="/export/new">New Export Offer</Link>, "19"),
          getItem(<Link to="/export/list">View Export Offers</Link>, "20"),
          // getItem(<Link to="/proforma/new">New Proforma</Link>, "21"),
          // getItem(<Link to="/proforma/list">View Proforma</Link>, "22"),
          getItem(<Link to="/export/final">Final Export Invoice</Link>, "23"),
          getItem(
            <Link to="/export/final_list">View Export Invoice</Link>,
            "24"
          ),
        ]
      ),
      getItem(
        "Admin Setting",
        "sub6",
        <SettingOutlined style={{ fontSize: "0.8rem" }} />,
        [
          getItem("Employee", "sub6_1", null, [
            getItem(<Link to="/admin/employee">View Employees</Link>, "25"),
            getItem(
              <Link to="/admin/create-employee">Add Employee</Link>,
              "26"
            ),
          ]),
          getItem("File Manager", "sub6_2", null, [
            getItem(<Link to="/admin/files">View Files</Link>, "27"),
          ]),
          getItem("Controls", "sub7_3", null, [
            getItem(
              <Link to="/admin/upload-clients">Upload Clients Detail</Link>,
              "29"
            ),
          ]),
        ]
      ),
    ], // Define menu items for admin
    storepurchase: [
      getItem("Store", "sub7", <i className="fa fa-home"></i>, [
        getItem("MRN", "sub7_1", null, [
          getItem(<Link to="/mrn/list">View MRN</Link>, "13"),
          getItem(<Link to="/mrn/create-mrn">New MRN</Link>, "14"),
        ]),
        getItem("Inventory", "sub7_2", null, [
          getItem(<Link to="/store/create-item">Add Item</Link>, "15"),
          getItem(<Link to="/store/add-stock">Add Stock</Link>, "16"),
          getItem(<Link to="/store/assign-stock">Assign Stock</Link>, "17"),
          getItem(<Link to="/store/item-stock">View Stock</Link>, "18"),
          // getItem(<Link to="/store/item-ledger">View Ledger Item</Link>, "28"),
        ]),
      ]),
      getItem("Purchase", "sub8", <i className="fa fa-shopping-cart"></i>, [
        getItem("Vendor Details", "sub8_1", null, [
          getItem(<Link to="/purchase/vendors">View Vendors</Link>, "7"),
          getItem(<Link to="/purchase/vendors/new">Add New Vendor</Link>, "8"),
        ]),
        getItem("Purchase Order", "sub8_2", null, [
          getItem(<Link to="/purchase/list">View PO's</Link>, "9"),
          getItem(<Link to="/purchase/new">New PO</Link>, "10"),
        ]),
      ]),
    ], // Define menu items for storepurchase
    sales: [
      getItem("Sales", "sub9", <i className="fa fa-users"></i>, [
        getItem("Clients Details", "sub9_1", null, [
          getItem(<Link to="/sales/clients">View Clients</Link>, "1"),
          getItem(<Link to="/sales/client/new">Add New Client</Link>, "2"),
        ]),
        getItem("Lead Details", "sub9_2", null, [
          getItem(<Link to="/sales/leads">View Leads</Link>, "3"),
          getItem(<Link to="/sales/leads/new">New Lead</Link>, "4"),
        ]),
        getItem("Quotation", "sub9_3", null, [
          getItem(<Link to="/sales/invoicelist">View Quotations</Link>, "5"),
          getItem(<Link to="/sales/create-quotation">New Quotation</Link>, "6"),
        ]),
      ]),
      // getItem("Exports", "sub10", <i className="fa fa-ship"></i>, [
      //   getItem(<Link to="/export/new">New Export Offer</Link>, "19"),
      //   getItem(<Link to="/export/list">View Export Offers</Link>, "20"),
      //   // getItem(<Link to="#">New Proforma</Link>, "21"),
      //   // getItem(<Link to="#">View Proforma</Link>, "22"),
      //   getItem(<Link to="/export/final">Final Export Invoice</Link>, "23"),
      //   getItem(<Link to="/export/final_list">View Export Invoice</Link>, "24"),
      // ]),

      getItem("Production", "sub11", <i className="fa fa-industry"></i>, [
        getItem(<Link to="/orders/list">View Job Orders</Link>, "11"),
        getItem(<Link to="/orders/create-job">New Job Order</Link>, "12"),
      ]),
    ], // Define menu items for sales
    purchase: [
      getItem("Purchase", "sub12", <i className="fa fa-shopping-cart"></i>, [
        getItem("Vendor Details", "sub12_1", null, [
          getItem(<Link to="/purchase/vendors">View Vendors</Link>, "7"),
          getItem(<Link to="/purchase/vendors/new">Add New Vendor</Link>, "8"),
        ]),
        getItem("Purchase Order", "sub12_2", null, [
          getItem(<Link to="/purchase/list">View PO's</Link>, "9"),
          getItem(<Link to="/purchase/new">New PO</Link>, "10"),
        ]),
      ]),
    ], // Define menu items for purchase
    orders: [
      getItem("Production", "sub13", <i className="fa fa-industry"></i>, [
        getItem(<Link to="/orders/list">View Job Orders</Link>, "2"),
        getItem(<Link to="/orders/create-job">New Job Order</Link>, "3"),
      ]),
    ], // Define menu items for orders
    store: [
      getItem("Store", "sub14", <i className="fa fa-home"></i>, [
        getItem("MRN", "sub14_1", null, [
          getItem(<Link to="/mrn/list">View MRN</Link>, "13"),
          getItem(<Link to="/mrn/create-mrn">New MRN</Link>, "14"),
        ]),
        getItem("Inventory", "sub14_2", null, [
          getItem(<Link to="/store/create-item">Add Item</Link>, "15"),
          getItem(<Link to="/store/add-stock">Add Stock</Link>, "16"),
          getItem(<Link to="/store/assign-stock">Assign Stock</Link>, "17"),
          getItem(<Link to="/store/item-stock">View Stock</Link>, "18"),
          // getItem(<Link to="/store/item-ledger">View Ledger Item</Link>, "28"),
        ]),
      ]),
    ], // Define menu items for store
  };

  const getMenuItems = () => {
    const uniqueRoles = Array.from(new Set(user_roles)); // Remove duplicate roles
    const allMenuItems = [];
    const addedMenuKeys = new Set(); // To keep track of added menu items
    const addedMenuLabels = new Set(); // To keep track of added menu labels

    // Define the desired sequence for the menu items
    const menuOrder = [
      "Sales",
      "Purchase",
      "Production",
      "Store",
      "Exports",
      "Admin Setting",
    ];

    // Always add Dashboard as the first item
    const dashboardItem = getItem(
      <Link to={`/admin`}>Dashboard</Link>,
      "dashboard",
      <i className="fa fa-tachometer" />
    );
    allMenuItems.push(dashboardItem);
    addedMenuKeys.add("dashboard");
    addedMenuLabels.add("Dashboard");

    // Helper function to recursively add menu items
    const addMenuItem = (item) => {
      if (addedMenuKeys.has(item.key)) return null;

      addedMenuKeys.add(item.key);

      if (item.children) {
        const newChildren = item.children
          .map(addMenuItem)
          .filter((child) => child !== null);

        if (newChildren.length === 0) return null;

        return { ...item, children: newChildren };
      }

      return item;
    };

    uniqueRoles.forEach((userRole) => {
      if (roleMenuItems.hasOwnProperty(userRole)) {
        roleMenuItems[userRole].forEach((menuItem) => {
          const newMenuItem = addMenuItem(menuItem);

          // Check if the label is unique before adding the item
          if (newMenuItem !== null && !addedMenuLabels.has(newMenuItem.label)) {
            allMenuItems.push(newMenuItem);
            addedMenuLabels.add(newMenuItem.label); // Track the label to ensure uniqueness
          }
        });
      }
    });

    // Separate the dashboard item from other items
    const nonDashboardItems = allMenuItems.filter(
      (item) => item.key !== "dashboard"
    );

    // Sort the non-dashboard items based on the predefined menuOrder
    nonDashboardItems.sort((a, b) => {
      const orderA = menuOrder.indexOf(a.label);
      const orderB = menuOrder.indexOf(b.label);

      // If the item label is not found in the menuOrder, it should appear last
      return (
        (orderA === -1 ? menuOrder.length : orderA) -
        (orderB === -1 ? menuOrder.length : orderB)
      );
    });

    // Prepend the Dashboard item to the sorted items
    const finalMenuItems = [dashboardItem, ...nonDashboardItems];

    console.log("show all menu items:", finalMenuItems);

    return finalMenuItems;
  };

  const menuItems = getMenuItems();

  const onClick = (e) => {
    setCurrent(e.key);
  };
  const rootSubmenuKeys =
    ["sub1", "sub2", "sub4", "sub5", "sub6", "sub7"] || [];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider
      width={250}
      height="100vh"
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      className="site-layout-background"
    >
      <div className="logo ">
        <Link
          to={`/admin`}
          onClick={() => {
            setCurrent();
            setOpenKeys(["sub1"]);
          }}
          className="navbar-brand logo col-md-3"
        >
          {collapsed ? (
            <img
              src={Min_Logo}
              className="d-flex mx-auto"
              alt="logo"
              width={"70px"}
            />
          ) : (
            <img
              src={Logo}
              className="d-flex mx-auto"
              alt="logo"
              width={"220px"}
            />
          )}
        </Link>
      </div>
      <Menu
        theme="light"
        onClick={onClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ height: "100vh" }}
        selectedKeys={[current]}
        mode="inline"
        items={menuItems}
      />
    </Sider>
  );
};

export default CommonSidebar;
